/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/HighlightOff';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { grey } from '@mui/material/colors';

import { useTranslation } from '../common/components/LocalizationProvider';
import RemoveDialog from '../common/components/RemoveDialog';
import PositionValue from '../common/components/PositionValue';
import { useDeviceReadonly, useRestriction } from '../common/util/permissions';
import usePersistedState from '../common/util/usePersistedState';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { devicesActions } from '../store';
import { useCatch } from '../reactHelper';
import SendCommandDialog from '../common/components/SendCommandDialog';

const useStyles = makeStyles((theme) => ({
  card: {
    width: theme.dimensions.popupMaxWidth,
    borderRadius: theme.spacing(1.5),
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '50px',
    height: '50px',
    filter: 'brightness(0) invert(1)',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '5px',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  label: {
    fontSize: '10px',
    color: grey[600],
  },
  mIcon: {
    width: '38px',
    color: theme.palette.colors.primary,
    height: '38px',
    border: '1px solid #003165',
    borderRadius: '5px',
    padding: theme.spacing(0.5, 0.5),
    '&:hover': {
      backgroundColor: '#30D5C8',
    },
  },
  mdIcon: {
    width: '38px',
    height: '38px',
    border: '1px solid #003165',
    borderRadius: '5px',
    padding: theme.spacing(0.5, 0.5),
    '&:hover': {
      backgroundColor: '#30D5C8',
    },
  },
  IconButton: {
    flexDirection: 'column',
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({ deviceId, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);
  const position = useSelector((state) => state.positions.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const [commandSendDialogOpen, setCommandSendDialogOpen] = useState(false);

  const positionAttributes = usePositionAttributes(t);

  const initialPositionItems = ['speed', 'address', 'totalDistance', 'course'];

  // Determine if 'fuel' should be included based on the fuel value
  const shouldIncludeFuel = position?.attributes?.fuel !== undefined && position.attributes.fuel >= 0 && position.attributes.fuel <= 500;

  const [positionItems, setPositionItems] = usePersistedState('positionItems', [...initialPositionItems, ...(shouldIncludeFuel ? ['fuel'] : [])]);

  useEffect(() => {
    // Update positionItems based on the fuel value
    setPositionItems([...initialPositionItems, ...(shouldIncludeFuel ? ['fuel'] : [])]);
  }, [position?.attributes?.fuel, shouldIncludeFuel]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleCommandSendDialogClose = () => {
    setCommandSendDialogOpen(false);
  };

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  return (
    <>
      {device && (
        <Card elevation={3} className={classes.card}>
          {deviceImage ? (
            <CardMedia
              className={classes.media}
              image={`/api/media/${device.uniqueId}/${deviceImage}`}
            >
              <IconButton size="small" onClick={onClose}>
                <CloseIcon color="#003165" fontSize="small" className={classes.mediaButton} />
              </IconButton>
            </CardMedia>
          ) : (
            <div className={classes.header}>
              <Typography style={{ fontWeight: 600 }} variant="body2" color="#003165">
                {device.name}
              </Typography>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          )}
          {position && (
            <CardContent className={classes.content}>
              <Table size="small" classes={{ root: classes.table }}>
                <TableBody>
                  {positionItems.filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                    <StatusRow
                      key={key}
                      name={positionAttributes[key].name}
                      content={(
                        <PositionValue
                          position={position}
                          property={position.hasOwnProperty(key) ? key : null}
                          attribute={position.hasOwnProperty(key) ? null : key}
                          appendPercentage={key === 'fuel'}
                        />
                      )}
                    />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          )}
          <CardActions classes={{ root: classes.actions }} disableSpacing>
            <IconButton
              color="secondary"
              disableRipple
              onClick={() => navigate(`/position/${position.id}`)}
              disabled={!position}
              classes={{ root: classes.IconButton }}
            >
              <InfoIcon className={classes.mIcon} />
              <div className={classes.label}>
                Info
              </div>
            </IconButton>
            <IconButton
              onClick={() => navigate('/replay')}
              disabled={!position}
              disableRipple
              classes={{ root: classes.IconButton }}
            >
              <ReplayIcon className={classes.mIcon} />
              <div className={classes.label}>
                Playback
              </div>
            </IconButton>
            <IconButton
              onClick={() => setCommandSendDialogOpen(true)}
              disabled={readonly}
              disableRipple
              classes={{ root: classes.IconButton }}
            >
              <PublishIcon className={classes.mIcon} />
              <div className={classes.label}>
                Command
              </div>
            </IconButton>
            <IconButton
              onClick={() => navigate(`/settings/device/${deviceId}`)}
              disableRipple
              disabled={deviceReadonly}
              classes={{ root: classes.IconButton }}
            >
              <EditIcon className={classes.mIcon} />
              <div className={classes.label}>
                Edit
              </div>
            </IconButton>
            <IconButton
              onClick={() => setRemoving(true)}
              disabled={deviceReadonly}
              disableRipple
              className={classes.negative}
              classes={{ root: classes.IconButton }}
            >
              <DeleteIcon className={classes.mdIcon} />
              <div className={classes.label}>
                Delete
              </div>
            </IconButton>
          </CardActions>
        </Card>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
      <SendCommandDialog
        open={commandSendDialogOpen}
        onClose={handleCommandSendDialogClose}
        deviceId={deviceId}
      />
    </>
  );
};

export default StatusCard;
